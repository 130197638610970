<template>
    <div class="member-order">
        <div class="container">
            <!-- 步骤条 -->
            <div class="order-steps">
                <el-steps 
                    :active="order_status" 
                    finish-status="success"
                    class="steps-box">
                    <el-step title="待付款"></el-step>
                    <el-step title="待确认"></el-step>
                    <el-step title="已同意/交易中"></el-step>
                    <el-step title="已拒绝/已取消"></el-step>
                    <el-step title="已完成"></el-step>
                    <el-step title="退款"></el-step>
                </el-steps>
            </div>
            <order-check
                ref="content"
                :detail="order_detail"
                :is-pay="isPay">
                <template #basic>
                    <order-basic
                        :detail="order_detail">
                        <template #default>
                            <p>订单时间</p>
                            <p>{{order_detail.orderTime}}</p>
                            <p>服务时间</p>
                            <p>{{order_detail.bookTime}}</p>
                            <p>商品名称</p>
                            <p>{{order_detail.goods_name}}</p>
                            <p>是否需要化妆师</p>
                            <p>{{order_detail.need_dress==1? '是': '否'}}</p>
                            <p>单价</p>
                            <p>{{order_detail.unit_price}}</p>
                        </template>
                    </order-basic>
                </template>
            </order-check>
            <div class="order-submit" v-if="isPay">
                <el-button
                    type="danger"
                    @click="handlePay"
                    class="submit-btn">
                    付款
                </el-button>
            </div>
        </div>
        <!-- dialog -->
        <el-dialog
            :visible="dialog"
            @close="dialog = false"
            width="300px"
            :show-close="false"
            center
            class="order-dialog">
            <div style="text-align: center">
                <template v-if="payMode == 'wechat'">
                    <div class="dialog-wechat">
                        扫码支付
                    </div>
                    <vue-qr
                        :text="pay_src"
                        :size="200"
                        :margin="0"
                        :auto-color="true"
                        :dotScale="1">
                    </vue-qr>
                </template>
                <img :src="require('@/assets/icons/icon_success.png')" alt="" v-if="payMode == 'alipay'">
                <el-button
                    type="warning"
                    class="dialog-finish"
                    @click="handleClose">
                    <span>完成支付</span>
                </el-button>
            </div>
        </el-dialog>
    </div>

</template>

<script>
    import { mapMutations, mapState, mapActions } from 'vuex';

    export default {
        data() {
            return {
                component: 'orderCheck',
                dialog: false,
                payMode: '',
                pay_src: ''
            }
        },
        computed: {
            ...mapState({
                is_login: state => state.user.is_login,
                user_id: state => state.user.user_id,
                order_status: state => state.order.order_status,
                order_detail: state => state.order.order_detail
            }),
			isPay() {
			    return this.order_status == 1
			}
          
        },
        components: {
            VueQr: () => import('vue-qr'),
            orderCheck: () => import('@/components/orderDetail/order-check'),
            orderBasic: () => import('@/components/orderDetail/order-basic')
        },
        methods: {
            ...mapMutations([
                'setOrderStatus',
                'setMemberOrder'
            ]),
            ...mapActions([
                'getMemberOrderDetail'
            ]),
            getData(orderID) {
                let userID = this.user_id,
                    url = `usercenter/ushopOrderDetail?userID=${userID}&orderID=${orderID}`;
                this.getMemberOrderDetail(url);
            },
            handlePay() {
                let radio = Number(this.$refs.content.radio),
                    mode = '';

                switch (radio){
                    case 1: // 账户余额
                        this.$message({
                            showClose: true,
                            message: "该支付方式暂未开通！",
                            type: "warning"
                        })
                        break;
                    case 2: // 微信支付
                        this.$message({
                            showClose: true,
                            message: "该支付方式暂未开通！",
                            type: "warning"
                        })
                        break;
                    case 3: // 支付宝
                        let url = `${this.$_url_.baseUrl}payment/alipay?userID=${this.user_id}&orderID=${this.order_detail.orderID}&type=${4}`;

                        window.open(url);
                        mode = 'alipay';
                        break;
                    default: // 账户余额
                        this.$message({
                            showClose: true,
                            message: "请选择支付方式！",
                            type: "warning"
                        })
                        break;
                }

                this.payMode = mode;
                this.dialog = mode != '';
            },
            handleClose() {
                this.dialog = false;
                this.getData(this.order_detail.orderID)
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.getData(to.query.id)
            })
        },
        beforeRouteLeave (to, from, next) {
            this.setOrderStatus(0);
            this.setMemberOrder({});
            next();
        }
    }
</script>

<style scoped>
.member-order{
    background-color: #fff;
    margin: 50px 0;
	
	
}
.order-steps{
    padding: 50px 80px;
}
.steps-box>>> .el-step__head.is-success,
.steps-box >>> .el-step__title.is-success{
    color: #8d9afc;
    border-color: #8d9afc;
}
.order-submit {
    margin-top: 20px;
    text-align: right;
}
.submit-btn {
    width: 200px;
    height: 60px;
}

.dialog-wechat {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
}
.dialog-finish {
    width: 100%;
    margin-top: 15px;
}
</style>